import { useState, useEffect, useContext , useRef} from "react";
import { useForm, setError } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../ContextApi";
import { toast, ToastContainer } from "react-toastify";
import { Register_banner_img } from "../Assets/index";
import { DEFAULT_CAPTCHA_KEY } from "../Constants";
import ReCAPTCHA from 'react-google-recaptcha';

const Register = () => {
  const [registerdata, setregisterdata] = useState({});
  const [otpinputbox, setotpinputbox] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [otpApi, setotpApi] = useState(null);
  const [valid, setvalid] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [isMobileVerified, setisMobileVerified] = useState(false);
  const [isOtpVerified, setisOtpVerified] = useState(false);
  
  const [mobilemssage, setmobilemessage] = useState("");
  const [otpMessage, setotpMessage] = useState("");
  const [mobileNumber, setmobileNumber] = useState("");
  const { token, setToken } = useContext(AppContext);
  const [otp, setOtp] = useState("");
  const [errorFields, setErrorFields] = useState([]);
  const [successMessage, setsuccessMessage] = useState("");
  const [EmployeeCode, setEmployeeCode] = useState("");
  const [GroupCode, setGroupCode] = useState("");
  const [ErrorsFields_Msg, setErrorsFields_Msg] = useState({});
  const [hideinputFields, setHideinputFields] = useState(true);
  const [employeeName, setEmployeeName] = useState("");
  const [isLoading , setIsLoading ] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [showRecaptchabtn , setRecaptchBtn] = useState(true)
  const [email , setEmail] = useState(null);
  const [empData , setEmpData] = useState({
    empName : "",
    empMob : "" ,
    empEmail :""
  })

  const [hideEmployeeCodeinputFields, sethideEmployeeCodeinputFields] =
    useState(false);

  const navigate = useNavigate();

  var requestOptions = {
    method: "POST",
    redirect: "follow",
    Authorization: `Bearer ${token}`,
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onCaptchaChange = (token) => {
    setCaptchaToken(token);
};

  async function fetchData() {
    const response = await fetch( process.env.REACT_APP_API_URL +
      `/generate-web-otp?mobile_number=${mobileNumber}&template=register&g-recaptcha-response=${captchaToken}`,
      requestOptions
    );
    const data = await response.json();
    try {
      if (data.success) {
        setotpApi(data);
        setotpinputbox(true);
        setisMobileVerified(true);
        setmobilemessage(data.message);
      } else {
        setisMobileVerified(false);
        setmobilemessage(data.message);
        setotpinputbox(false);
        recaptchaRef.current.reset();
        setCaptchaToken(null);
      }
    } catch (error) {
        recaptchaRef.current.reset();
        setotpinputbox(false);
        toast.error("Oops! You have reached maximum limit, please try again after an hour.")
        setCaptchaToken(null)
    };
    
    
  }

  // const handleMobileNumberChange = (e) => {
  //   const inputvalue = e.target.value;
  //   setmobileNumber(e.target.value);
  //   // Regular Expression pattern for Mobile number validation
  //   const pattern = /^\d{10}$/;
  //   const isValid = pattern.test(inputvalue);

  //   isValid ? setvalid(isValid) : setvalid(false);
  // };

  const handleOtpChange = (event) => {
    const value = event.target.value;
    setOtp(value);
  };

  const recaptchaRef = useRef(null)

  const mobile = register("mobile_number", { required: true });

  // useEffect(() => {
  //   if(mobileNumber?.length == 10){
      
  //   }
  //   mobileNumber != "" && mobileNumber?.length == 10 ? fetchData() : setotpinputbox(false);
  // }, [mobileNumber]);

  useEffect(() =>{
    if(mobileNumber?.length === 10){
      setRecaptchBtn(true)
      if(captchaToken){
        fetchData()
        setotpinputbox(true);
      }
    }
    else if(mobileNumber?.length >= 9){
      recaptchaRef?.current?.reset();
      setCaptchaToken(null)
      setotpinputbox(false)
      setOtp("")
    }
  },[mobileNumber,captchaToken])


  useEffect(() => {
    otp.length === 6 ? setIsOtpValid(true) : setIsOtpValid(false);
  }, [otp]);

  const CallEmpAPi = () => {
    setIsLoading(true);
    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "GET";
    fetch( process.env.REACT_APP_API_URL +
      `/check-employee?employee_code=${EmployeeCode}&group_code=${GroupCode}`,
      requestOptionsCopy
    )
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.success) {
          // Clear error messages when the API call is successful
          setErrorsFields_Msg({});
          setHideinputFields(false);
          setEmployeeName(res.data.employee_name);
          setEmpData({
            empName:  res.data.employee_name,
            empMob: res.data.mobile_number,
            empEmail: res.data.email_id
          });
          setmobileNumber(res.data.mobile_number);
          setEmail(res?.data?.email_id);
          setIsLoading(false);
          // console.log(res);
        } else {
          setErrorsFields_Msg(res.errors_fields);
          setIsLoading(false);
          // console.log(ErrorsFields_Msg)
          toast.error(res.message, {
            position: toast.POSITION.TOP_CENTER, // Customize toast position
            autoClose: 2000, // Close the toast after 3 seconds
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error verifying OTP:", error);
      });
  };

  const submitHandler = async (e) => {
    e.preventDefault();


    const data = {
      email : email ,
      name : employeeName,
      employee_code : EmployeeCode,
      group_code : GroupCode,
      mobile_number : mobileNumber , 
      otp : otp ,
      source : 0
    }
    
    console.log(data)
    setIsLoading(true)
    try {
      const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await fetch( process.env.REACT_APP_API_URL +"/employee-register", requestOptions);
      const result = await response.json();

      if (result.success === false) {
        setErrorFields(result.errors_fields);
        setIsLoading(false);
        localStorage.setItem('isAuthenicated' , true)
        Object.keys(result.errors_fields).forEach((key) => {
          const errorMessage = result.errors_fields[key].join(", ");
          toast.error(errorMessage, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
        });
      } else {
        setErrorFields(result.errors_fields);
        setsuccessMessage(result.message);
        setIsLoading(false);
        toast.success(result.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      console.error("error", error);
      setIsLoading(false);
    }
  };

  function validateNumberInput(e) {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  }

  useEffect(() =>{
    console.log(empData?.empName)
  },[empData])

  return (
    <>
      <div className="register-main-form-cover">
        <div className="container">
          <div className="register-box-section">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-5 p-0">
                <div className="register-left-background-cover">
                  <img src={Register_banner_img} alt="Data not found" />
                </div>
              </div>
              <div className="col-lg-5 p-0">
                <div className="form-main-cover-wrapper">
                  <form>
                    <h3 className="mb-4 text-center">Register</h3>
                    <div className="row">
                      {hideinputFields ? (
                        <>
                          <div className="col-lg-12">
                            <div className="form-input-box mb-4">
                              <div className="form-input">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="form-label"
                                >
                                  Employee Code *
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id=""
                                  value={EmployeeCode}
                                  aria-describedby="emailHelp"
                                  placeholder="Enter employee code"
                                  {...register("employee_code", {
                                    required: "Employee code is required",
                                  })}
                                  onChange={(e) =>
                                    setEmployeeCode(e.target.value)
                                  }
                                />
                                <small className="text-danger">
                                  {ErrorsFields_Msg?.employee_code}
                                </small>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-input-box mb-4">
                              <div className="form-input">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="form-label"
                                >
                                  Group Code *
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id=""
                                  value={GroupCode}
                                  placeholder="Enter corporate code"
                                  {...register("group_code", {
                                    required: "Group code is required",
                                  })}
                                  onChange={(e) => setGroupCode(e.target.value)}
                                />
                                <small className="text-danger">
                                  {ErrorsFields_Msg?.group_code}
                                </small>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {hideinputFields ? (
                        <div className="col-lg-12">
                          <button
                            className="Next-btn"
                            type="button"
                            onClick={CallEmpAPi}
                          >
                            {isLoading ? "please wait..." : "Next"}
                          </button>
                        </div>
                      ) : (
                        <>
                          <div className="col-lg-12">
                            <div className="form-input-box mb-4">
                              <div className="form-input">
                                <label
                                  htmlFor="name-field"
                                  className="form-label"
                                >
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="name-field"
                                  placeholder="Enter Name"
                                  defaultValue={employeeName}
                                  disabled={(empData && empData.empName)}
                                  onChange={(e) =>setEmployeeName(e.target.value)}
                                />
                                 <input
                                      type="hidden"
                                      {...register("name", { required: false })}
                                      defaultValue={employeeName}
                                  />
                                  <input
                                      type="hidden"
                                      {...register("source", { required: false })}
                                      name="source"
                                      value={0}
                                  />
                                
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-input-box mb-4">
                              <div className="form-input">
                                <label
                                  htmlFor="email-field"
                                  className="form-label"
                                >
                                  Email
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id="email-field"
                                  placeholder="Enter email"
                                  defaultValue = {email}
                                  disabled={(empData && empData.empEmail)}
                                  onChange={(e) =>setEmail(e.target.value)}
                                />
                                {/* <input
                                    type="hidden"
                                    name="email"
                                    defaultValue={email}
                                    {...register("email", { required: false })}
                                /> */}
                                
                  
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-input-box mb-3">
                              <div className="form-input">
                                <label
                                  htmlFor="mobile-field"
                                  className="form-label"
                                >
                                  Mobile *
                                </label>
                                {/* <input
                                    type="hidden"
                                    name="mobile"
                                    {...register("mobile", { required: false })} 
                                    defaultValue={mobileNumber}
                                /> */}
                                
                                <input
                                  type="text"
                                  className="form-control mb-3"
                                  id="mobile-field"
                                  placeholder="Enter mobile number"
                                  disabled={(empData && empData.empMob)}
                                  maxLength={10}
                                  defaultValue={mobileNumber}
                                  onChange={(e) =>setmobileNumber(e.target.value)}
                                  
                                />
                                { showRecaptchabtn && (
                                    <ReCAPTCHA
                                      sitekey={DEFAULT_CAPTCHA_KEY}
                                      onChange={onCaptchaChange}
                                      ref ={recaptchaRef}
                                    />
                                  )}
                                {/* {mobileNumber !== "" && (
                                  <small
                                    className={
                                      isMobileVerified
                                        ? "text-success"
                                        : "text-danger"
                                    }
                                  >
                                    {isMobileVerified
                                      ? mobilemssage
                                      : mobilemssage}
                                  </small>
                                )} */}
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {otpinputbox ? (
                        <div className="col-lg-6">
                          <div className="form-input-box mb-3">
                            <div className="form-input">
                              <label
                                className="form-label"
                                htmlFor="otp-field"
                              >
                                Enter Otp
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                maxLength="6"
                                onInput={(e) => validateNumberInput(e)}
                                id="otp-field"
                                placeholder="Enter six digits otp"
                                {...register("otp", {
                                  required: "otp  is required",
                                })}
                                onChange={(e) => {
                                  mobile.onChange(e);
                                  handleOtpChange(e); // your method
                                }}
                              />
                            </div>
                            {ErrorsFields_Msg.otp && (
                              <small>{ErrorsFields_Msg.otp}</small>
                            )}
                            {otp !== "" && (
                              <small>
                                {isOtpValid
                                  ? isVerified
                                    ? otpMessage
                                    : otpMessage
                                  : ""}
                              </small>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div> 
                    {isOtpValid && (
                      <div className="submit-btn-box">
                        <button
                          type="button"
                          className="register-form-btn mt-4 mb-3"
                          disabled={isLoading}
                          onClick={submitHandler}
                        >
                          {isLoading ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
    position="top-center"
/>
      </div>
    </>
  );
};

export default Register;
